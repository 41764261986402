import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import MatchLaneCompare from "@/game-deadlock/components/MatchLaneCompare.jsx";
import MatchScoreboard from "@/game-deadlock/components/MatchScoreboard.jsx";
import MatchSoulsGraph from "@/game-deadlock/components/MatchSoulsGraph.jsx";
import { Tabs } from "@/game-deadlock/Match.style.jsx";
import { updatePreferredMatchTab } from "@/game-deadlock/utils/actions.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import getPlayer from "@/game-deadlock/utils/get-player.mjs";
import { playerAccolades } from "@/game-deadlock/utils/player-match-accolades.mjs";
import DotIcon from "@/inline-assets/dot.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { classNames } from "@/util/class-names.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TAB_OPTIONS = [
  {
    key: "",
    label: ["common:scoreboard", "Scoreboard"] as Translation,
  },
  {
    key: "lane-performance",
    label: ["deadlock:lanePerformance", "Lane Performance"] as Translation,
  },
  {
    key: "souls",
    label: ["deadlock:stats.teamSouls", "Team Souls"] as Translation,
  },
];

export default function Match() {
  const { t } = useTranslation();
  const {
    parameters: [matchId, steamId],
  } = useRoute();

  const {
    deadlock: { matches },
  } = useSnapshot(readState);

  const match = matches[matchId];
  const player = useMemo(() => getPlayer(steamId, match), [steamId, match]);

  const isWin = useMemo(
    () => !!(match && player && match?.winning_team === player.team),
    [match, player],
  );

  const accolades = useMemo(() => {
    return playerAccolades({ steamId, matchData: match });
  }, [steamId, match]);

  const [tab, setTab] = useQuery<string>("tab", "");

  return (
    <SharedMatchLayout
      match={match}
      title={
        match && player && match.winning_team === player.team
          ? t("common:victory", "Victory")
          : t("common:defeat", "Defeat")
      }
      image={
        Assets.getHeroPortrait(player?.hero_id) || Assets.getDeadlockLogo()
      }
      imageLink={`/deadlock/profile/${steamId}`}
      borderColor={isWin ? "var(--turq)" : "var(--red)"}
      underTitle={
        <>
          {[
            match?.duration_s > 0 &&
              t("common:minutes", "{{minutes}} mins", {
                minutes: formatDuration(
                  sanitizeNumber(match.duration_s * 1000),
                  "m:ss",
                ),
              }),
            matchId &&
              t("deadlock:matchId", "Match ID: {{matchId}}", { matchId }),
          ]
            .filter(Boolean)
            .map((i) => (
              <React.Fragment key={i}>
                <span>{i}</span>
                <DotIcon style={{ color: "var(--shade3)" }} />
              </React.Fragment>
            ))}
          {!!match?.start_time && (
            <div>
              <TimeAgo
                date={new Date(match.start_time * 1000)}
                className={undefined}
              />
            </div>
          )}
        </>
      }
      badges={accolades}
    >
      <MainColumnsContainer>
        <Card padding="0" classNameOuter="span-all" className={Tabs()}>
          <header className="tabs-header">
            <nav className="tabs-list">
              {TAB_OPTIONS.map((option) => {
                return (
                  <button
                    key={option.key}
                    onClick={() => {
                      setTab(option.key);
                      updatePreferredMatchTab(option.key);
                    }}
                    {...classNames(
                      "tabs-tab type-form--tab",
                      tab === option.key && "active",
                    )}
                  >
                    {t(...option.label)}
                  </button>
                );
              })}
            </nav>
          </header>
          <div className="tabs-content">
            {tab === "lane-performance" ? (
              <MatchLaneCompare matchId={matchId} steamId={steamId} />
            ) : tab === "souls" ? (
              <MatchSoulsGraph matchId={matchId} steamId={steamId} />
            ) : (
              <MatchScoreboard matchId={matchId} steamId={steamId} />
            )}
          </div>
        </Card>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}

export function meta([_, steamId]) {
  const name = readState.deadlock.steam[steamId]?.name ?? "Unknown";
  return {
    title: [
      "deadlock:meta.match.title",
      "{{name}}'s Match Stats – Blitz Deadlock",
      { name },
    ],
    description: [
      "deadlock:meta.match.description",
      "Match Stats for {{name}}",
      { name },
    ],
  };
}
